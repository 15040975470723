import startImg from "../images/cafetiere_web_ready_trans.png";
import endImg from "../images/totoro_coffee2.webp";

const mainImage = document.getElementById("main-image");
const body = document.body;
const altImage = document.getElementById("alt-image");
const instructions = document.getElementById("instructions");
const timer = document.getElementById("timer-display");
const timerDisplay = document.getElementById("timer-display");
const tuneSelector = document.getElementById("tune");
const minsSelector = document.getElementById("time-duration-mins");
const secsSelector = document.getElementById("time-duration-secs");
const successWords = document.getElementById("success-words");
const resetMessage = "Tap again to reset...";
const startMessage = "Tap image to start!";

let arg = "start";

mainImage.addEventListener("click", () => {
  startStopCountdown(arg, tuneSelector, minsSelector, secsSelector);
});

function startStopCountdown(startStop, tunes, mins, secs) {
  const tune = tunes.value;
  const timeMins = mins.value;
  const timeSecs = secs.value;
  let totalTime = Number(timeMins) * 60 + Number(timeSecs);

  if (startStop === "start") {
    arg = "stop";
    resetMainImage(mainImage, successWords);
    setInstructions(instructions, resetMessage);
    stopAudio(tune);
    removeReadyClass();
    altImage.classList.add("heating");
    startAudioCountdown(tune, totalTime);

    timer.innerHTML = totalTime;

    setInterval(function() {
      if (timer.innerHTML <= 1) {
        stopHeating(altImage);
        clearAllIntervals();
        successImage(mainImage, successWords);
        rotator(mainImage);
        addReadyClass();
      }
      totalTime = totalTime - 1;
      timer.innerHTML = totalTime;
    }, 1000);
  } else {
    clearAllIntervals();
    resetMainImage(mainImage, successWords);
    resetTimer(timerDisplay);
    stopAudio(tune);
    setInstructions(instructions, startMessage);
    removeReadyClass();
    arg = "start";
  }
}

function addReadyClass() {
  body.classList.add("coffee-time");
}
function removeReadyClass() {
  body.classList.remove("coffee-time");
}

function startAudioCountdown(tune, time) {
  let countdownTime = time * 1000 - 10;
  setTimeout(() => playAudio(tune), countdownTime);
}

function playAudio(tune) {
  let audio = document.getElementById(tune);
  audio.load();
  audio.play();
}

function setInstructions(element, message) {
  element.innerHTML = message;
}

function clearAllIntervals() {
  for (let i = 0; i < 100; i++) {
    window.clearInterval(i);
  }
}

function stopAudio(tune) {
  let audio = document.getElementById(tune);
  audio.load();
}

function resetMainImage(imageTarget, words) {
  clearAllIntervals();
  imageTarget.classList.remove("rotating");
  imageTarget.setAttribute("src", startImg);
  words.classList.remove("visible");
  stopHeating(altImage);
}

function successImage(imageTarget, words) {
  imageTarget.setAttribute("src", endImg);
  words.classList.add("visible");
}

function rotator(imageTarget) {
  let image = imageTarget;
  image.classList.add("rotating");
}

function resetTimer(timerDisplay) {
  timerDisplay.innerHTML = 0;
}

function stopHeating(altImage) {
  altImage.classList.remove("heating");
}
